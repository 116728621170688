import React from 'react';
import moment from "moment";
import SubmissionError from 'redux-form/lib/SubmissionError';
import queryString from 'query-string';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import FilterFormComponent from 'components/common/FilterForm';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import CompanyFilterComponent from 'components/common/CompanyFilter';
import ResetPasswordFormModalComponent from './ResetPasswordForm';
import JobListFormModalComponent from './jobListFormModal'
import { toast } from 'react-toastify';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import ResetPasswordIcon from './reset-password.png';
import JobIcon from './jobIcon.png'
import AddEmployeeIcon from './add-employee.png';
import InActiveDefaultIcon from './inactive-d.png';
import ActiveIcon from './mark-active-default.png';
import showHideIcon from './showHideIcon.png'

import { FILTER_OPTIONS, MSGS, CREATED_DATE_FILTER } from 'constants/employees';
import styles from './styles.module.scss';

class EmployeeFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.state = { isOpen: false, isLoading: false, isExporting: false, attributeToggler: false };
    this.toggle = this.toggle.bind(this);
    this.toggleJobFormModal = this.toggleJobFormModal.bind(this);
    this.goTo = this.goTo.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    //this.updateStatusofEmployee = this.updateStatusofEmployee.bind(this);
  }

 /**
 * Shows or hides extra information based on the checkbox status.
 * Updates the filters and navigates to the new URL with the updated query parameters.
 * 
 * @param {Object} val - The event object from the checkbox.
 * @param {Object} val.target - The target of the event.
 * @param {boolean} val.target.checked - The checked status of the checkbox.
 * @returns {void}
 */
  showExtraInformation(val) {
    const { history, location: { pathname, search } } = this.props;
    const query  = queryString.parse(search);
    const {show_extra_info , ...rest } = query;
    this.props.updateFilters({show_extra_info: val.target.checked, ...rest})
    return history.push({
      pathname,
      search: queryString.stringify({ ...query, show_extra_info: val.target.checked }),
    });
  }

  // updateStatusofEmployee(status){
  //   const { selectedEmployees } = this.props;
  //   const {history,location:{pathname, search} } = this.props;
  //   const query = queryString.parse(search);
  //   this.props.usecase.updateStatus(status, selectedEmployees.map((i)=> i.id), query)
  //   .then(()=>{
  //     this.props.usecase.getEmployeeList({...query,page:1})
  //     history.push({
  //       pathname,
  //       search:queryString.stringify({...query,page:1}),
  //     });
  //   });
  // }
/**
* Navigates to a specified URL.
* 
* @param {string} url - The URL to navigate to.
* @returns {void}
*/
  goTo(url) {
    const { history } = this.props;
    return history.push(url)
  }

/**
* Updates the query parameters when a department is selected.
* 
* @param {string} data - The department ID or a flag indicating no department.
* @returns {void}
*/
  onDepartmentSelect(data) {
    let id = null;
    let nullDept = null;
    if (data === "true") {
      nullDept = true;
    } else {
      id = data;
    }  
    const { history, location: { pathname, search }} = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    
    history.push({
      pathname,
      search: queryString.stringify({
        ...rest,
        department_id: id,
        null_department: nullDept?nullDept:undefined,
        team_id: null,
      }),
    });
  }
  

/**
* Handles the date range filter by updating the query parameters.
* 
* @param {number} val - The number of days for the date range, or 0 to remove the date range filter.
* @returns {void}
*/
  handleDateRange(val) {
    const { history, location: { pathname, search }} = this.props
    let query  = queryString.parse(search);
    if(val !== 0){
      query = {
        ...query,
        end_date: moment(new Date()).format("YYYY-MM-DD"),
        start_date: moment(new Date()).subtract(val, 'days').format("YYYY-MM-DD"),
      }
    }else{
      delete query.start_date;
      delete query.end_date;
    }
    history.push({
      pathname,
      search: queryString.stringify({...query, date_created_range: val}),
    });
  }

/**
* Toggles the visibility of the job list modal.
* 
* @returns {void}
*/
  toggleJobFormModal() {
    this.setState({ isJobListModalOpen : !this.state.isJobListModalOpen});
  }

/**
* Toggles the state of the component (e.g., open/close state).
* 
* @returns {void}
*/
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

/**
* Resets the password for selected employees and handles success or error cases.
* 
* @param {Object} data - The password data.
* @param {string} data.password - The new password.
* @param {string} data.confirm_password - The confirmation of the new password.
* @returns {void}
*/
  resetPassword(data) {
    const { selectedEmployees } = this.props;

    this.props.usecase.resetPassword({
      employee_id: selectedEmployees.map((i) => i.id),
      password:data.password,
      re_password:data.confirm_password,

    })
      .then(() => {
        toast.success(MSGS.CHANGE_PASSWORD_SUCCESS);
        this.setState({ isOpen: !this.state.isOpen });
      })
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }


  render() {
    const { location, location: { pathname, search } , history, subCompanyList, departmentList , selectedEmployees, isExporting,
      teamList, isOrganizationVisible, isSubCompany, companyPermissions, setAttributeToggler, showableAttributes, allJobs, isButtonLoading } = this.props;
    const { isOpen , isJobListModalOpen} = this.state;

    const nullDeptExists = departmentList.some(department => department.id === 0);

    let departmentOptions;
   if( nullDeptExists ){

    departmentOptions = departmentList.filter((item)=>item.name !== "Null Department"); 
        departmentOptions.splice(1, 0, { id: "true", name: "Null Department" }); 
   }
   else{
    departmentOptions = [...departmentList];
   }

    // Check if "Null Department" is already in the list
    
    let teamOptions = teamList;
    let isExtraInfo = false;
    let parsedQuery ;
    const query = queryString.parse(search);
    const companyId = query && Object.keys(query)?.length ? JSON.parse(query.company_id) : [] ;
    let subCompanyObj = [];

    if (subCompanyList?.length > 0 && companyId) {
      subCompanyObj = subCompanyList.map((item) => {
       let obj = {...item};
        if (companyId.indexOf(item.id) >= 0) {
          obj.checked = true
        }
        else {
          obj.checked=false
        }
        return obj;
      });
    }
    if (Object.keys(query)?.length > 0) {
      parsedQuery = JSON.parse(JSON.stringify(query));
      if(parsedQuery.show_extra_info === "true") isExtraInfo=true;
    }

    const selectedActiveEmployees = selectedEmployees.filter((i) => i.is_active);
    const selectedInActiveEmployees = selectedEmployees.filter((i) => !i.is_active);
    

    return (
      <div className="ml-3 mr-4">
        <Row className="filter">
          <Col md={12} className="pl-0 mb-2 pr-0">
            <Col className="float-left" md={6}>         
              <div className="float-left"> 
                {(subCompanyList && subCompanyList?.length >= 1) && (isSubCompany === null) && isOrganizationVisible ?
                  <div className={`${styles['allbranch-width']} float-left d-inline-block mr-1`}>
                    <CompanyFilterComponent
                      location={location}
                      subCompanyAll={subCompanyObj}
                      toggleVendor={(data) => this.props.usecase.toggleVendor(data, companyId, query.department_id )}
                      className={styles['border-round']}
                    />
                  </div> : null}
                 {departmentOptions && departmentOptions?.length > 0  && isOrganizationVisible &&
                  <div className="float-left d-inline-block"> 
                    <FilterDropdownComponent
                      options={departmentOptions}
                      location={location}
                      history={history}
                      paramKey={query.department_id===null?"null_department":"department_id"}
                      valueLabel="name"
                      keyLabel="id"
                      onChange={(val) => this.onDepartmentSelect(val)}
                    />
                   </div>}
              </div>
            </Col>
            <Col>
              <div className="float-right">
                <section className="float-left ">
                  <Button
                    color="accent"
                    className="mr-1" 
                    disabled={!selectedEmployees?.length || !allJobs.length}
                    onClick={() => this.toggleJobFormModal()}
                  >
                    <div id="edit_job" className="mr-1">
                      <img
                        className={styles['button-icon']}
                        src={JobIcon}
                        alt="Icon"
                      />
                      <UncontrolledTooltip placement="top" target="edit_job">
                        Edit Job
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                  <Button
                    color="accent"
                    className="mr-1"
                    disabled={!selectedEmployees?.length}
                    onClick={() => this.toggle()}
                  >
                    <div id="reset_password" className="mr-1">
                      <img
                        className={styles['button-icon']}
                        src={ResetPasswordIcon}
                        alt="Icon"
                      />
                      <UncontrolledTooltip placement="top" target="reset_password">
                        Reset Password
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                  {isOpen &&
                    <ResetPasswordFormModalComponent
                      isOpen={isOpen}
                      toggle={this.toggle}
                      resetPassword={this.resetPassword}
                    />}
                  {isJobListModalOpen &&
                    <JobListFormModalComponent
                      isOpen={isJobListModalOpen}
                      toggle={this.toggleJobFormModal}
                      resetPassword={this.resetPassword}
                      location={location}
                      allJobs={allJobs}
                      selectedEmployees={selectedEmployees}
                      isButtonLoading={isButtonLoading}
                      usecase={this.props.usecase}
                    />
                  }
                 
                  {companyPermissions.manage_settings ? <Button
                    id="add_employee"
                    color="accent"
                    className="mr-1"
                    onClick={() => this.goTo({
                      pathname: '/employee/add',
                      search: queryString.stringify({
                        parent: `${pathname}${search}`,
                      })
                    })}
                  >
                    <img
                      className={styles['button-icon']}
                      src={AddEmployeeIcon}
                      alt="Icon"
                    />
                    <UncontrolledTooltip placement="top" target="add_employee">
                      Add Employee
                    </UncontrolledTooltip>
                  </Button> : null}
                  <Button
                    color="accent"
                    className="mr-1"
                    disabled={!selectedActiveEmployees?.length}
                    onClick={() => this.props.checkEmpsJobAssignMentStatus(true)}
                  >
                    <div id ="mark_inactive">
                      <img
                        className={styles['button-icon']}
                        src={InActiveDefaultIcon}
                        alt="Icon"
                      />
                      <UncontrolledTooltip placement="top" target="mark_inactive">
                        Mark Inactive
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                  <Button
                    color="accent"
                    className="mr-1"
                    disabled={!selectedInActiveEmployees?.length}
                    onClick={() => this.props.updateStatusofEmployee(true)}
                  >
                    <div id ="mark_active">
                      <img
                        className={styles['button-icon']}
                        src={ActiveIcon}
                        alt="Icon"
                      />
                      <UncontrolledTooltip placement="top" target="mark_active">
                        Mark Active
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                </section>
                <div className="d-inline-block override-cross-bulk">
                  <FilterFormComponent
                    location={this.props.location}
                    placeholder="id,name,email,title,phone or tag"
                    initialValues={query}
                  />
                </div>
              </div>
            </Col>
          </Col>
        </Row>
        <Row className="filter">
          <Col md={12} className="pl-0 mb-2 pr-0">
            <Col className="float-left" md={6}>  
              {teamOptions && teamOptions?.length > 0  && isOrganizationVisible &&
                <div className="float-left d-inline-block">
                  <FilterDropdownComponent
                    options={teamOptions}
                    location={location}
                    history={history}
                    paramKey="team_id"
                    valueLabel="name"
                    keyLabel="id"
                  />
                </div>}
                <div className="float-left d-inline-block">
                  <FilterDropdownComponent
                    options={FILTER_OPTIONS}
                    location={location}
                    paramKey="is_active"
                  />
                </div>
                <div className="float-left d-inline-block">
                  <FilterDropdownComponent
                    options={CREATED_DATE_FILTER}
                    location={location}
                    paramKey="date_created_range"
                    onChange={(val) => this.handleDateRange(val)}
                  />
                </div>
              </Col>  
              <Col md={6} className="float-right pr-2">
                <Button
                  title="Export Employees"
                  color="accent" 
                  className="float-right"
                  onClick= { () => this.props.usecase.export(query, selectedEmployees.map((i) => i.id), showableAttributes)}
                >
                Export <ButtonLoaderAtom active={isExporting} />
                </Button>
                <Button
                  color="accent"
                  className="mr-3 ml-2 bulk-emp-btn"
                  style={{float: 'right'}}
                  onClick={() => this.goTo({
                      pathname: '/employee/bulk-upload',
                      search: queryString.stringify({
                        parent: JSON.stringify(query),
                      })
                    })}
                >
                  Bulk Upload
                </Button>
                <Button
                  onClick={() => setAttributeToggler(!this.props.attributeToggler)}
                  color="accent"
                  style={{float: 'right'}}
                >
                  <div id="show_hide">
                    <img
                      className={styles['button-icon-hide']}
                      src={showHideIcon}
                      height="12"
                      width="15"
                      alt="Icon"
                    />
                    <UncontrolledTooltip placement="top" target="show_hide">
                        Show/Hide
                    </UncontrolledTooltip>
                  </div>
                </Button>
              </Col>
            </Col>
          </Row>
          <Row className="filter">
            <Col md={12} className="pl-0 mb-2 pr-3">
              <Col className="float-left" md={6}>  
                {(departmentOptions?.length > 0) && isOrganizationVisible ?
                  <div className="mt-2 font-styling">
                    <input
                      id="show_extra_info"
                      type="checkbox"
                      name="show_extra_info"
                      onChange={(e) => this.showExtraInformation(e)}
                      checked={isExtraInfo}
                      className="mr-1 align-text-bottom"
                    />
                    <span className={styles['checkbox-label-custom']}>
                      {subCompanyList?.length > 0  
                        ? "Show Branch, Departments & Teams"
                        : "Show Department & Teams"
                      }
                    </span>
                  </div>
                : null}
              </Col>
            </Col>
          </Row>
      </div>            
    );
  }}

export default EmployeeFilterForm;
