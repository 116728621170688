import React from 'react';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { Form,FormGroup, FormText, Label, Input , Button, Row, Col , Collapse} from 'reactstrap';
import { toast } from 'react-toastify';

import DateTimePickerAtom from 'generics/DateTimePickerFormik'; 
import Flatpickr from 'react-flatpickr';
import FormikSelectAtom from 'generics/FormikSelect';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CountryCodeFormik from 'generics/CountryCodeFormik';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import SectionLoaderAtom from 'atoms/SectionLoader';
import TagModal from './TagModal';
import FormikMultiSelectAtom from 'atoms/FormikMultiselectDropdown';


import caretArrowUp from './caret-arrow-up.png';
import caretArrowDown from './caret-down.png';
import MultiSelectDropdownAtom from 'atoms/FormikMultiselect';
import styles from './styles.module.scss';
import { UncontrolledTooltip } from 'reactstrap';



import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';
import EmployeeInactiveConfirmationModal from "employees/components/EmployeeInactiveConfirmationModal";
import {sanitizationDangerousHTML} from "common/sanitization.js"


class EmployeeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.state = { 
      employeeInfoOpen:true ,
      organisationalInfoOpen : false,
      contactInfoOpen : false,
      saving: false,
      invite: false,
      savingandinvite: false,
      savingAndAddNew: false,
      showPasswordFields: false,
      inputType: 'password',
      showStrengthCheck : false,
      tagsList: this.props.initialValues?.tags || [],
      isOpenTagModal : false,
      submitTag : false,
      employeeInactiveConfirmationModal : false ,
      validationDone : false,
      savingandinviteClick: false,
      savingClick: false,
      refreshLoader : false,
      policiesList : [],
      multiDeptslist: [],
      setHasNullDepartment:this.props.initialValues.null_department ?this.props.initialValues.null_department: false,
      filteredRoles:this.props.initialValues.department_supervisor?this.props.initialValues.department_supervisor: [],
      department_supervisor_checkbox: this.props.initialValues.department_supervisor_access?this.props.initialValues.department_supervisor_access:false,
      selectedDepts: this.props.initialValues.supervisory_departments? this.props.initialValues.supervisory_departments: [],
      selectedTeams:this.props.initialValues.supervisory_teams? this.props.initialValues.supervisory_teams: [],
    };
    this.handleDeptChange = this.handleDeptChange.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.selectTags = this.selectTags.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.handleLoginPermissionChange = this.handleLoginPermissionChange.bind(this);
    this.closeEmployeeInactiveModal = this.closeEmployeeInactiveModal.bind(this);
    this.updateJobAssignmentofEmployee = this.updateJobAssignmentofEmployee.bind(this);
    this.toggleEmployeeInactiveModal = this.toggleEmployeeInactiveModal.bind(this)
    this.formikRef = React.createRef();
    this.selectPolicies = this.selectPolicies.bind(this)
    this.handleDepartmentSupervisorChange = this.handleDepartmentSupervisorChange.bind(this);
  }

  /**
   * Handles the change of department supervisor status and updates the form value.
   *
   * @param {Object} e - The event object from the checkbox input.
   * @param {Function} setFieldValue - Function to set the field value in the form.
   */
  handleDepartmentSupervisorChange = (e, setFieldValue) => {
    const { checked } = e.target;
    if(checked === false){
      this.setState({selectedDepts:[],selectedTeams:[]})
    }
    setFieldValue('department_supervisor', checked);
  };

  /**
  * Lifecycle method that is called after the component has mounted.
  * Initializes the state with filtered department list if required.
  */
  componentDidMount(){
    const { data: {departmentList, teamList }} = this.props;
    // const filteredDept = departmentList.filter(item => item?.id !== this.props?.initialValues?.department);
    // this.setState({multiDeptslist:filteredDept.length > 0 ? filteredDept : []})
  }

  /**
  * Handles changes in login permission settings and updates the form values accordingly.
  *
  * @param {Object} e - The event object from the input.
  * @param {Function} setFieldValue - Function to set the field value in the form.
  */
  handleLoginPermissionChange(e, setFieldValue){
    if(e.target.id == 'offline_mode'){
      setFieldValue('kiosk_user', false);
      setFieldValue('dual_login', false);
      setFieldValue('offline_mode', e.target.checked);
    }
    else if(e.target.id == 'kiosk_user'){
      setFieldValue('dual_login', false);
      setFieldValue('offline_mode', false);
      setFieldValue('kiosk_user', e.target.checked);
    }
    else if(e.target.id == 'dual_login'){
      setFieldValue('kiosk_user', false);
      setFieldValue('offline_mode', false);
      setFieldValue('dual_login', e.target.checked);
    }
  }

  /**
  * Toggles the display of strength check feature.
  */
  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  /**
  * Updates the tags list in the component state based on the selected tags.
  *
  * @param {Array} tags - The array of selected tags.
  */
  selectTags(tags){  
    const tagsId = tags  && tags.map(item => item.id );
    this.setState({tagsList: tagsId});
  }

  /**
  * Updates the policies list in the component state based on the selected policies.
  *
  * @param {Array} policies - The array of selected policies.
  */
  selectPolicies(policies){  
    const policiesId = policies  && policies.map(item => item.id );
    this.setState({policiesList: policiesId});
  }

  /**
  * Toggles the visibility of the tag modal.
  */
  toggleTagModal(){
    const { isOpenTagModal } = this.state;
    this.setState({isOpenTagModal : !isOpenTagModal});
  }
  
  /**
  * Handles the form submission with appropriate error handling and state updates.
  *
  * @param {Object} values - The form values.
  * @param {Object} formikHelpers - Formik helpers including setSubmitting, setErrors, and resetForm.
  */
  async onSubmit(values, { setSubmitting, setErrors, resetForm }) {
    setErrors({});
    const { inputType, invite ,validationDone, selectedDepts,selectedTeams,setHasNullDepartment} = this.state;
    const { phoneFieldMandatory,initialValues } = this.props;
    if((values.is_active || (values.is_active===initialValues.is_active)) || validationDone)
    {
    this.updateState('savingandinviteClick',false)
    this.updateState('savingClick',false)
    this.closeEmployeeInactiveModal()
    this.setState({validationDone:false})
    this.props.usecase.setup_data({ ...values,supervisory_teams: selectedTeams.length ? selectedTeams : [] ,supervisory_departments: selectedDepts.length ? selectedDepts.filter((item)=>item!=="true") : [],null_department : setHasNullDepartment,first_name: values.first_name.trim(), phone_no : values.phone_no ? this.props.phoneNumberPlain : null, showPasswordFields: this.state.showPasswordFields, hire_date:values.hire_date? (/^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.exec(values.hire_date)?values.hire_date:moment(values.hire_date).format("DD/MM/YYYY")): null},
      inputType, invite, phoneFieldMandatory, this.state.tagsList,this.state.policiesList);
    try {
      const employee = await this.props.usecase.execute(this.state.savingAndAddNew);
      if(employee && employee.id){
        resetForm({});
      }
      this.updateState('saving', false);
      this.updateState('savingAndAddNew', false);
      this.updateState('savingandinvite', false);

    } catch (e) {
      this.updateState('saving', false);
      this.updateState('savingAndAddNew', false);
      this.updateState('savingandinvite', false);
      if(typeof e === 'string'){
        toast.error(e)
      }else setErrors(e.errors);
    }
    setSubmitting(false);
  }else
  {
    this.toggleEmployeeInactiveModal(initialValues.jobs_present)
  }
  }

  /**
  * Toggles the employee inactive confirmation modal based on the status.
  *
  * @param {boolean} status - The status to show or hide the modal.
  */
  toggleEmployeeInactiveModal(status) {
    const {savingandinviteClick,savingClick} = this.state

    this.updateState('validationDone',true)
    if(status)
    {
      this.updateState('employeeInactiveConfirmationModal',status)
    }else
    {
      this.updateState('validationDone',true)
      if(savingandinviteClick){
        this.updateState('savingandinvite', true);
        this.updateState('invite', true);
      }else if(savingClick)
      {
        this.updateState('saving', true);
        this.updateState('invite', false);
      }
      this.formikRef.current.submitForm()
    }
  }

  /**
  * Updates the job assignment of the employee and handles state updates.
  *
  * @param {boolean} status - The status indicating whether to refresh or not.
  */
  updateJobAssignmentofEmployee(status){
    const {
      params
    } = this.props;
    
    const id= parseInt(params.id)
    let arrOfID = new Array()
    arrOfID.push(id)
    const empId_Obj={
        employee_ids:arrOfID
      }
      
    const {savingandinviteClick,savingClick} = this.state
    if(!status)
    {
      this.updateState('refreshLoader',true)
       this.props.usecase
           .updateUserJobAssignment(
               empId_Obj
             ).then(() => {

       this.updateState('validationDone',true)
       if(savingandinviteClick){
        this.updateState('savingandinvite', true);
        this.updateState('invite', true);
       }else if(savingClick)
       {
        this.updateState('saving', true);
        this.updateState('invite', false);
       }
       this.updateState('refreshLoader',false)
        this.formikRef.current.submitForm()
      });
    }else
    {
      this.updateState('validationDone',true)
      if(savingandinviteClick){
        this.updateState('savingandinvite', true);
        this.updateState('invite', true);
      }else if(savingClick)
      {
        this.updateState('saving', true);
        this.updateState('invite', false);
      }
    
      this.formikRef.current.submitForm()
    }
}

/**
* Toggles the input type between password and text.
*/
   toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

/**
* Updates the component state with the provided key and value.
*
* @param {string} key - The key of the state to update.
* @param {any} value - The new value to set for the state.
*/
  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

/**
* Handles the change in department selection and updates the form value.
*
* @param {Object} department - The selected department object.
* @param {Function} setFieldValue - Function to set the field value in the form.
*/
  handleDeptChange(department, setFieldValue){
    // if (this.state.selectedDepts.length > 0) {
    //   const updatedSelectedDepts = this.state.selectedDepts.filter(
    //     dept => dept !== department?.value
    //   );
    //   this.setState({ selectedDepts: updatedSelectedDepts });
    //   this.props.usecase.handleDepartmentChange(updatedSelectedDepts);
    // }
    
    const { data: {departmentList, teamList }} = this.props;
    //  const filteredDept = departmentList.filter(item => item?.id !== department?.value);
    // this.setState({multiDeptslist:filteredDept.length > 0 ? filteredDept : []})
    setFieldValue('team', '');
    if(department){
      this.props.usecase.handleDepartmentChange(department.value);
    }
  }


// Handles department change when multiple departments can be selected. Updates state with selected departments and handles "Null Department".
/**
 * Handles the change in department selection and updates the component state.
 * Checks if "Null Department" is selected and updates the state accordingly.
 *
 * @param {Array} department - The array of selected departments.
 * @param {Function} setFieldValue - Function to set the field value in the form.
 */
handleDepartmentChange(department, setFieldValue) {
    let check = department.some(item => item.name === "Null Department");
    this.setState({ setHasNullDepartment: check });
    let selectedDepts = department.filter(i => i.name !== "Null Department").map(i => i.id);
    this.setState({ selectedDepts: selectedDepts });
      this.props.usecase.handleDepartmentChange(selectedDepts);
    
  }



  /**
   * Handles team selection and updates the selected teams' IDs in the component state.
   *
   * @param {Array} team - The array of selected teams.
   */
  handleTeamChange(team) {
    let selectedTeams = team.map(i => i.id);
    this.setState({ selectedTeams: selectedTeams });
  }

  /**
   * Handles role change by filtering the role list and updating the department supervisor checkbox state.
   * If the selected role requires department supervision, the checkbox is set to true.
   * Otherwise, it resets the selected departments and teams and unchecks the department supervisor checkbox.
   *
   * @param {Object} role - The selected role object.
   * @param {Function} setFieldValue - Function to set the field value in the form.
   */
  handleRoleChange(role, setFieldValue) {
    const { data: { roleList } } = this.props;
    const filteredrole = roleList.filter(item => item?.id === role?.value);
    this.setState({ filteredRoles: filteredrole });
    if (filteredrole[0]?.department_supervisor) {
      this.setState({ department_supervisor_checkbox: true });
    }
    else{
      this.setState({ department_supervisor_checkbox: false, selectedDepts: [], selectedTeams: [] });
      setFieldValue('department_supervisor', false);
    }
  }


 
  /**
   * Handles company selection change by resetting the team, department, and employee role fields.
   * Calls the company change handler from props to update company-related data.
   *
   * @param {Object} company - The selected company object.
   * @param {Function} setFieldValue - Function to set the field value in the form.
   */
  handleCompanyChange(company, setFieldValue){
    setFieldValue('team', '');
    setFieldValue('department', '');
    setFieldValue('employee_role', '');
    this.props.usecase.handleCompanyChange(company.value);
  }

  /**
   * Handles country selection change by resetting the state field.
   * Calls the country change handler from props to update country-related data.
   *
   * @param {Object} country - The selected country object.
   * @param {Function} setFieldValue - Function to set the field value in the form.
   */
  handleCountryChange(country, setFieldValue) {
    setFieldValue('state', '');
    this.props.usecase.handleCountryChange(country.value);
  }

  /**
   * Closes the employee inactive confirmation modal and resets the validation status.
   */
  closeEmployeeInactiveModal(){
     this.setState({employeeInactiveConfirmationModal:false,validationDone:false})
  }



 
  render(){
    const { employeeInfoOpen, organisationalInfoOpen, contactInfoOpen, showPasswordFields, saving, savingAndAddNew, inputType , savingandinvite , isOpenTagModal , employeeInactiveConfirmationModal , refreshLoader , multiDeptslist , selectedDepts,setHasNullDepartment} = this.state;
    
    const { data: { roleList, countryList, stateList, companyList, departmentList, teamList,multiTeamList,multiDepartmentList }, phoneFieldMandatory,
      isLoading, isOrganizationVisible, params, companyPermissions, isSubCompany, initialValues , getLoginLink , employeeTagsList, formatPhoneNumber, phoneNumberPlain,dateFormat,dateFormatDisplay , companyPoliciesList} = this.props;
      let updatedRoleList;
      const roleSelected = roleList.length && roleList.find((i) => i.id === ((initialValues.employee_role && initialValues.employee_role.value) || initialValues.employee_role));
            if(roleSelected && roleSelected.name === 'Client Admin' && !roleSelected.is_edit){
        updatedRoleList = roleList;
      }else {
        updatedRoleList = roleList.filter((i) => !((i.name === 'Client Admin' && !i.is_edit) || (i.name === 'Applicant' && !i.is_edit)));
      }
      const tags = employeeTagsList&& employeeTagsList.length && employeeTagsList[0].tag_collection && employeeTagsList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));
    //this.setState({tagsList: tags});
      const policies = companyPoliciesList && companyPoliciesList.length && companyPoliciesList.map(item =>(
        { id: item.policy_id , name: item.policy_name }
      ))
    if(isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          ref={this.formikRef}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            setFieldValue,
         }) => {
            const roleSelected = roleList.length && roleList.find((i) => i.id === ((values.employee_role && values.employee_role.value) || values.employee_role));
            return (<Form  autocomplete = "off">
              <div style={{minHeight: '3vh', fontSize: '20px'}}>
                <FormText color="danger">{errors && errors.non_field_errors}</FormText>
              </div>
              <div>
                <div className='d-flex justify-content-between'>
                  <div>
                    <b>Employee Information</b>
                    <span onClick={() => this.updateState('employeeInfoOpen', !employeeInfoOpen)} role="button" tabIndex="0" className="remove-focus" >
                      <img className='ml-2 mr-2' src= {employeeInfoOpen ? caretArrowUp : caretArrowDown } alt= ''  height="10" width="10" />
                    </span>
                  </div>
                  {params.id === undefined ?
                  null:
                  <Button size='sm' outline style={{marginTop: '-8px'}} onClick={()=> this.props.updateState('toggleEmployeeNotes', true)}>Employee Notes</Button>
                  
                  }
                  </div>
                <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
              </div>
              <Collapse isOpen={employeeInfoOpen}>
                <div className = "toggleCard">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>First Name</Label>
                        <Input
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("first_name",val)
                          }}
                          value={values && values.first_name ? values.first_name : ''}
                          className={errors && errors.first_name ? "form-control-error" : null}
                          data-lpignore={true}
                          autoComplete="off"
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.first_name}</FormText></div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                          type="text"
                          name="last_name"
                          value={values && values.last_name ? values.last_name : ''}
                          placeholder="Last name (optional)"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("last_name",val)
                          }}
                          className={errors && errors.last_name ? "form-control-error" : null}
                          data-lpignore={true}
                          autoComplete="off"
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.last_name}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <Row>
                          <Col md={4} className="pr-0">
                            <Field
                              id="phone_ext"
                              name="phone_ext"
                              label="Code"
                              component={CountryCodeFormik}
                            />
                            <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_ext}</FormText></div>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Phone Number</Label>
                              <Input
                                type="text"
                                name="phone_no"
                                placeholder="(805) 555-0123"
                                onChange={(e)=> {
                                  let val = sanitizationDangerousHTML(e.target.value);
                                  setFieldValue("phone_no",val)
                                }}
                                onBlur={handleBlur}
                                value={values.phone_no ? formatPhoneNumber(values.phone_no) : ''}
                                invalid={errors && touched.phone_no && errors.phone_no}
                                className={errors && errors.phone_no ? "form-control-error" : null}
                                data-lpignore={true}
                                autoComplete="off"
                              /> 
                              <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_no}</FormText></div>
                            </FormGroup>
                          </Col>
                        </Row>   
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>User Role</Label>
                        <Field
                          component={FormikSelectAtom}
                          name="employee_role"
                          id="employee_role"
                          options={updatedRoleList.map((i) => ({label: i.name, value: i.id }))}
                          placeholder="User Role"
                          onChange = {role => this.handleRoleChange(role,setFieldValue)}
                        />
                         <Input
                          component={FormikSelectAtom}                     
                          className={styles['hidden-field']}              
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.employee_role}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-4 mt-2">
                      <FormGroup>
                        <Input
                          type="checkbox"
                          id="offline_mode"
                          onChange= {(e)=>{this.handleLoginPermissionChange(e , setFieldValue)}}
                          className={errors && errors.offline_mode ? "form-control-error" : null}
                          checked={values && values.offline_mode}
                        />
                        <Label className="mb-0">Allow Offline Mode On Mobile App <b>(Beta)</b> <br /><small className={`${styles['label-part']}`}>(Such Employees can then interact with the app during poor network connectivity)</small></Label>
                        <div style={{minHeight: '2vh'}}><FormText color="danger">{errors && errors.offline_mode}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-4">
                      <FormGroup>
                         <Input
                            type="checkbox"
                            id="kiosk_user"
                            onChange= {(e)=>{this.handleLoginPermissionChange(e , setFieldValue)}}
                            className={errors && errors.kiosk_user ? "form-control-error" : null}
                            checked={values && values.kiosk_user}
                          />
                        <Label className="mb-0">Allow Kiosk Punch In <br /><small className={`${styles['label-part']}`}>(will lose permission to login with mobile app)</small></Label>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.kiosk_user}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-4">
                      <FormGroup>
                         <Input
                            type="checkbox"
                            id="dual_login"
                            onChange= {(e)=>{this.handleLoginPermissionChange(e , setFieldValue)}}
                            className={errors && errors.dual_login ? "form-control-error" : null}
                            checked={values && values.dual_login}
                          />
                        <Label className="mb-0">Allow Kiosk and Mobile App Login <b>(Beta)</b></Label>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.dual_login}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {params.id && initialValues &&
                    <div className="mb-3">
                      <Label className="mb-0">Employee's Username:</Label>
                      <div className={`${styles['username-div']} p-1`}> <b>{`${initialValues.username}`}</b></div>  
                    </div>
                  }
                  {params.id &&
                    <div>
                      <span  className="link-name mb-2" onClick={() => this.updateState('showPasswordFields', true)}><u><i>Change Password</i></u></span>
                    </div>}
                  {((values && values.kiosk_user && !(params.id)) || showPasswordFields || (!params.id && roleSelected && roleSelected.admin_access)
                      || (!phoneFieldMandatory && !params.id) || (!params.id && roleSelected && roleSelected.kiosk_user_access))  &&
                    <Row>
                      <Col className="pr-0 mr-2">
                        <FormGroup>
                          <div className="pos-relative">
                            <Label>Password*</Label>
                            <Input
                              type={inputType}
                              name="password"
                              placeholder="Password"
                              onChange={(e)=> {
                                let val = sanitizationDangerousHTML(e.target.value);
                                setFieldValue("password",val)
                              }}
                              onBlur={(e) => { this.strengthCheckToggle(); handleBlur(e)}}
                              onFocus = {this.strengthCheckToggle}
                              value={values.password}
                              invalid={errors && touched.password && errors && errors.password}
                              className={errors && errors.password ? "form-control-error" : null}
                              data-lpignore={true}
                              autoComplete="new-password"
                            />
                            {inputType === 'password'
                              ? (<EyeOpenIconAtom
                                className="password-icon"
                                height="22" width="22"
                                onClick={this.toggleInputType}
                              />)
                              : (<EyeCloseIconAtom
                                className="password-icon"
                                height="22" width="22"
                                onClick={this.toggleInputType}
                            />)
                          }
                          </div>
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.password}</FormText></div>
                          {this.state.showStrengthCheck &&
                            <PasswordStrengthMeter
                              values={values}
                            />
                          }
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          {inputType === 'password'
                            ?(
                            <div>
                            <Label>Confirm Password*</Label>
                            <Input
                              type="password"
                              name="confirm_password"
                              placeholder="Confirm Password"
                              onChange={(e)=> {
                                let val = sanitizationDangerousHTML(e.target.value);
                                setFieldValue("confirm_password",val)
                              }}
                              onBlur={handleBlur}
                              autoComplete="new-password"
                              value={values.confirm_password}
                              invalid={errors && touched.confirm_password && errors.confirm_password}
                              className={errors && errors.confirm_password ? "form-control-error" : null}
                              data-lpignore={true}
                            /> 
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.confirm_password}</FormText></div>
                          
                          </div>): null }
                        </FormGroup>
                      </Col>
                    </Row>}
                  <Row>
                    <Col className="ml-4">
                      <FormGroup>   
                        <Input
                          type="checkbox"
                          id="is_active"
                          onChange={handleChange}
                          className={errors && errors.is_active ? "form-control-error" : null}
                          checked={values && values.is_active} 
                          
                        />
                        <Label for="is_active" name="active" className="mr-5 mb-2">Active?</Label>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.is_active}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div>
                <b>Organizational Information (optional)</b> 
                <span onClick={() => this.updateState('organisationalInfoOpen', !organisationalInfoOpen)} role="button" tabIndex="0" className="remove-focus" >
                  <img className='ml-2 mr-2' src= {organisationalInfoOpen ? caretArrowUp : caretArrowDown } alt= ''  height="10" width="10" />
                </span>
                <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
              </div>
              <Collapse isOpen={organisationalInfoOpen}>
                <div className = "toggleCard"> 
                  <Row>
                    { isOrganizationVisible && companyList.length &&
                      <Col>
                        <FormGroup>
                          <Label>Company Name</Label>
                          <Field
                            id="company"
                            name="company"
                            component={FormikSelectAtom}
                            options={companyList.map((i) => ({ value: i.id, label: i.name }))}
                            placeholder="Company Name"
                            onChange={(v) => this.handleCompanyChange(v, setFieldValue)}
                            disabled={({}.hasOwnProperty.call(params, 'id') && companyPermissions.manage_organization === false) || (isSubCompany !== null)}
                          />
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.company}</FormText></div>
                        </FormGroup>
                      </Col>}
                    <Col>
                      <FormGroup>
                        <Label>Title</Label>
                        <Input
                          type="text"
                          name="title"
                          placeholder="Title"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("title",val)
                          }}
                          value={values && values.title ? values.title : ''}
                          className={errors && errors.title ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.title}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {departmentList.length > 0 && isOrganizationVisible &&
                  <>
                    <Row>
                      <Col>
                        <Label>User Department</Label>
                        <Field
                          id="department"
                          name="department"
                          component={FormikSelectAtom}
                          placeholder="User Department"
                          options={departmentList.map((i) => ({ value: i.id, label: i.name }))}
                          onChange={(v) => this.handleDeptChange(v, setFieldValue)}
                          disabled={{}.hasOwnProperty.call(params, 'id') && companyPermissions.manage_organization === false}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.department}</FormText></div>
                      </Col>
          
                      <Col>
                       <Label>User Team</Label>
                        <Field
                          id="team"
                          name="team"
                          component={FormikSelectAtom}
                          placeholder="User Team"
                          options={teamList.map((i) => ({ value: i.id, label: i.name }))}
                          disabled={{}.hasOwnProperty.call(params, 'id') && companyPermissions.manage_organization === false || !values.department}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.team}</FormText></div>
                      </Col>
                    </Row>
                    <Row>
                     
                    <Col className="ml-4 mb-2">
                      <FormGroup>  
                        <Input
                          type="checkbox"
                          id="department_supervisor"
                          onChange={(e) => this.handleDepartmentSupervisorChange(e, setFieldValue)}
                          // className={errors && errors.is_active ? "form-control-error" : null}
                          checked={values && values.department_supervisor} 
                          disabled={(this.state.department_supervisor_checkbox===false)}
                          
                        />
                        <Label for="department_supervisor" name="department_supervisor" className="mr-5 mb-2">Supervise Multiple Departments/Teams</Label>
                        {/* <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.is_active}</FormText></div> */}
                      </FormGroup>
                      {(this.state.department_supervisor_checkbox===false)&&
                      <UncontrolledTooltip placement="right" target="department_supervisor">
                      To enable the checkbox please select a user role having permission (Department Supervisor) to select multiple department and teams
                    </UncontrolledTooltip>
         }
                    </Col>
         
                  </Row>
                  </> }
                    
                    <Row style={{background:"#d1d1d1",lineHeight: '2rem', marginBottom: '1rem'}}>
                       <Col md={6}>
                       <Label>Other Departments</Label>
                       <Field
                         field={{value:this.state.selectedDepts}}
                         id="supervisory_departments"
                         name="supervisory_departments"
                         component={FormikMultiSelectAtom}
                         items={multiDepartmentList?.length>0 ? multiDepartmentList:[]}
                         placeholder="Select Departments"
                         itemToString={i => i && i.name}
                         onChange={data => this.handleDepartmentChange(data,setFieldValue)}
                         disabled={!values.department_supervisor}
                       />
                       <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.department}</FormText></div>
                     </Col>
                      <Col md={6}>
                       <Label>Other Teams</Label>
                        <Field
                          id="supervisory_teams"
                          name="supervisory_teams"
                          component={FormikMultiSelectAtom}
                          items={values.department_supervisor===false ||selectedDepts.length===0 ?[]:multiTeamList}
                          placeholder="Select Teams"
                          itemToString={i => i && i.name}
                         onChange={data => this.handleTeamChange(data)}

                          disabled={!values.department_supervisor||selectedDepts.length===0}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.team}</FormText></div>
                      </Col>
                    </Row>
                    

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Employee Id</Label>
                        <Input
                          type="text"
                          name="employee_id"
                          placeholder="Employee ID"
                          value={values && values.employee_id ? values.employee_id : ''}
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("employee_id",val)
                          }}
                          className={errors && errors.employee_id ? "form-control-error" : null}
                          disabled={{}.hasOwnProperty.call(params, 'id') && companyPermissions.manage_settings === false}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.employee_id}</FormText></div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Wage $/hr</Label>
                        <Input
                          type="text"
                          name="wage"
                          placeholder="Wage $/hr"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("wage",val)
                          }}
                          value={values && values.wage ? values.wage : ''}
                          className={errors && errors.wage ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.wage}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="w-100">Tags <span tabIndex="0" role="button" className="link-name float-right" onClick={()=>this.toggleTagModal()}>Manage tags</span></Label>
                        <span>
                          <Field
                            component={MultiSelectDropdownAtom}
                            id="tags"
                            name="tags"
                            onChange={handleChange}
                            placeholder="Select Tags"
                            items={tags}
                            itemToString={i => i && i.name}
                            className={styles.fixed_form_height}
                            value={values.tags ? values.tags : []}
                            selectItems={this.selectTags}
                          />
                         </span>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.employee_id}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                   <Col>
                      <FormGroup>
                        <Label>Hire Date</Label>
                        <Field
                            id="hire_date"
                            name="hire_date"
                            placeholder="Hire Date"
                            component={DateTimePickerAtom}
                            value={values && values.hire_date ? moment(values.hire_date, "DD/MM/YYYY").toISOString() : null}
                            onChange={handleChange}
                            className={{height:"10px"}}
                            options={{dateFormat: `${dateFormat}`}}
                          />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.hire_date}</FormText></div>
                         
                         </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Collapse>
              <div>
                <b>Contact Information (optional)</b>
                  <span onClick={() => this.updateState('contactInfoOpen', !contactInfoOpen)} role="button" tabIndex="0" className="remove-focus" >
                    <img className='ml-2 mr-2' src= {contactInfoOpen ? caretArrowUp : caretArrowDown } alt= ''  height="10" width="10" />
                  </span>
                  <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
              </div>
              <Collapse isOpen={contactInfoOpen}>
                <div className = "toggleCard"> 
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          type="text"
                          name="email"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("email",val)
                          }}
                          placeholder="Example@example.com"
                          value={values && values.email ? values.email : ''}
                          className={errors && errors.email ? "form-control-error" : null}
                          data-lpignore={true}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.email}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address (Line 1)</Label>
                        <Input
                          type="text"
                          name="address_line_one"
                          placeholder="Address"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("address_line_one",val)
                          }}
                          value={values && values.address_line_one ? values.address_line_one : ''}
                          className={errors && errors.address_line_one ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.address_line_one}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address (Line 2)</Label>
                        <Input
                          type="text"
                          name="address_line_two"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("address_line_two",val)
                          }}
                          value={values && values.address_line_two ? values.address_line_two : ''}
                          placeholder="Apartment,Suite,Unit,Building,Floor"
                          className={errors && errors.address_line_two ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.address_line_two}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Country</Label>
                        <Field
                          id="country"
                          name="country"
                          component={FormikSelectAtom}
                          options={countryList.map((i) => ({ value: i.id, label: i.name }) )}
                          onChange={(v) => this.handleCountryChange(v, setFieldValue)}
                          placeholder="Country"
                        /> 
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.country}</FormText></div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>State</Label>
                        <Field
                          id="state"
                          name="state"
                          component={FormikSelectAtom}
                          options={stateList.map((i) => ({ value: i.id, label: i.name }) )}
                          placeholder="State"
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.state}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>City</Label>
                        <Input
                          type="text"
                          name="city"
                          placeholder="City"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("city",val)
                          }}
                          value={values && values.city ? values.city : ''}
                          className={errors && errors.city ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.city}</FormText></div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Zip Code</Label>
                        <Input
                          type="text"
                          name="zip_code"
                          placeholder="Zip Code"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("zip_code",val)
                          }}
                          value={values && values.zip_code ? values.zip_code : ''}
                          className={errors && errors.zip_code ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.zip_code}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <Row>
                <Col className="pb-5 mb-4 pt-3">
                  <FormGroup> 
                    { params.id && !values.kiosk_user && values.phone_no ?
                      <div className="text-left d-inline">
                        <u><i><font color="#006CD1"><span onClick={() => getLoginLink()} className=" link-name">View login link</span></font></i></u>
                      </div>
                      :
                      null}
                    {!params.id ? <Button
                      className={`${styles['form-button']} ml-1 mr-1 btn btn-secondary btn-grp-alignment float-right`}
                      onClick={() => {
                        this.updateState('saving', true);
                        submitForm();
                      }}
                      isColor='primary'
                      isLoading={isSubmitting}
                      disabled={saving || savingAndAddNew}
                    >
                      { values && values.phone_no ?
                      'Save & Invite' : 'Save' } <ButtonLoaderAtom active={saving} />
                    </Button>
                    :
                    <Button
                      className={`${styles['form-button']} ml-1 mr-1 btn btn-secondary btn-grp-alignment float-right`}
                      onClick={() => {
                        if(values.is_active || (values.is_active===initialValues.is_active))
                        {
                        this.updateState('savingandinvite', true);
                        this.updateState('invite', true);
                        }else
                        {
                          this.updateState('savingandinviteClick',true)
                        }
                        submitForm();
                      }}
                      isColor='primary'
                      isLoading={isSubmitting}
                      disabled={savingandinvite || saving}
                    >
                      { values && values.phone_no ?
                      'Save & Invite' : 'Save' }<ButtonLoaderAtom active={savingandinvite} />
                    </Button>
                    }
                    {!params.id ?
                      <Button
                        className={`${styles['form-button']} ml-1 mr-1 btn btn-secondary btn-grp-alignment float-right`}
                        onClick={() => {
                          this.updateState('savingAndAddNew', true);
                          submitForm();
                        }}
                        isColor='primary'
                        isLoading={isSubmitting}
                        disabled={saving || savingAndAddNew}
                      >
                        { values && values.phone_no ? 'Save, Invite & Add New'
                        : 'Save & Add New'} <ButtonLoaderAtom active={savingAndAddNew} />
                      </Button>
                      :
                      (values && values.phone_no &&
                        <Button
                          className={`${styles['form-button']} ml-1 mr-1 btn btn-secondary btn-grp-alignment float-right`}
                          onClick={() => {
                            if(values.is_active || (values.is_active===initialValues.is_active))
                            {
                            this.updateState('saving', true);
                            this.updateState('invite', false);
                            }else
                            {
                              this.updateState('savingClick',true)
                            }
                            submitForm();
                          }}
                          isColor='primary'
                          isLoading={isSubmitting}
                          disabled={savingandinvite || saving}
                        >
                          Save<ButtonLoaderAtom active={saving} />
                        </Button>)
                    }
                  </FormGroup>
                </Col>
              </Row> 
               {
                  isOpenTagModal ?
                  <TagModal
                    isOpen={isOpenTagModal}
                    toggle={this.toggleTagModal}
                    tagsList = {tags}
                    usecase={this.props.usecase}
                    submitTag = {this.state.submitTag}
                    tagsCollection = {this.props.employeeTagsList}
                  />
                  :
                  null
              }    
             {
              employeeInactiveConfirmationModal && (
               <EmployeeInactiveConfirmationModal
                toggle={this.closeEmployeeInactiveModal}
                isOpen={employeeInactiveConfirmationModal}
                updateJobAssignmentofEmployee={this.updateJobAssignmentofEmployee}
                editForm='true'
                refreshLoader={refreshLoader}
            />
          )}
            </Form> 
          )}}
        />
      </div>
    );
  }
}

export default EmployeeForm;